import api from '../utils/api';

export const getEventPayments = async () => {
  const { data } = await api.get('/payment/events');

  return data;
}

export const getBalance = async () => {
  const { data } = await api.get('/users/connect-stripe');

  return data.data;
}

export const getStripeDashboardUrl = async (isAccount) => {
  let url = '/users/stripe-dashboard';
  if (isAccount) url += '?account=true';

  const { data } = await api.get(url);

  return data;
}

export const payout = async () => {
  await api.get('/users/payout');

  return;
}

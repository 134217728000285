import React, { Fragment } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import 'typeface-roboto';

import Navbar from './components/navbar';
import Footer from './components/footer';

import Routes from '../src/routes/route';
import store from './store';
import init from './init';

import './App.scss';

init();

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Navbar/> 
          <Routes />
          <Footer/>
        </Fragment>
      </Router>
    </Provider>
  )
}

export default App;

import {
  CognitoRefreshToken
} from 'amazon-cognito-identity-js';
import HttpStatus from 'http-status-codes';

import Userpool from '../Userpool';

export const getSession = () => {
  return new Promise(async (resolve, reject) => {
    const user = await getCurrentUser();

    if (!user) {
      return reject();
    } else {
      user.getSession((err, session) => {
        if (err) {
          return reject(err);
        } else {
          return resolve(session);
        }
      })
    }
  })
}

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const user = Userpool.getCurrentUser();

    return resolve(user);
  })
}

export const signout = () => {
  return new Promise(async (resolve, reject) => {
    const user = await getCurrentUser();
    console.log("sign out")
    if (!user) {
      // return reject({ message: 'User not found' });
      return reject();
    }

    try {
      // await forgetDevice();
      user.signOut();
      return resolve();
    } catch (err) {
      return reject(err);
    }
  })
}

export const forgetDevice = () => {
  return new Promise(async (resolve, reject) => {
    const user = await getCurrentUser();
    console.log("forget device limit out")
    if (!user) {
      return reject({ message: 'User not found' });
    }

    try {
      user.getSession((err, session) => {
        if (err) {
          return reject(err);
        }
        if (session.isValid()) {
          user.forgetDevice({
            onSuccess: function(result) {
              return resolve();
            },
            onFailure: function(err) {
              return reject(err);
            },
          })
        }
      })
    } catch (err) {
      return reject(err)
    }
  })
}

export const refreshSession = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const user = await getCurrentUser();
      const session = await getSession();

      const { refreshToken } = session;

      const RefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken?.token });
      user.refreshSession(RefreshToken, (err, session) => {
        if (err) {
          return reject({
            status: HttpStatus.UNAUTHORIZED
          })
        }

        return resolve();
      })
    } catch (err) {
      return reject({
        status: HttpStatus.UNAUTHORIZED
      })
    }
  })
}

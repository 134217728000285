import api from '../utils/api';
import * as cognito from '../commons/cognito';
import config from '../config';

/**
 * Fetch new accessToken when the current one expires
 * @param {string} refreshToken 
 * @returns 
 */
export const refresh = () => {
  return cognito.refreshSession();
}

/**
 * Login
 * @param {string} name 
 * @param {string} password 
 * @returns 
 */
export const login = async (name, password) => {
  const { data } = await api.post('/auth/login', { name, password });
  
  return data;
};

export const logout = async () => {
  await cognito.signout();
  window.location.href = `${config.MAIN_DOMAIN}/login?redirect_back_studio=1`;
} 

import HttpStatus from 'http-status-codes';

import api from '../utils/api';

import { logout, refresh } from '../redux/actions/auth';
import UserPool from '../Userpool';

let heldRequests = [];
let isTokenBeingRefreshed = false;

/**
 * Build authorization header.
 *
 * @param {string} accessToken
 *
 * @returns {string}
 */
function getAuthorizationHeader(accessToken) {
  return `Bearer ${accessToken}`;
}

/**
 * Interceptor to add Access Token header for all requests.
 *
 * @param {object} request
 *
 * @returns {object}
 */
export async function authorizationInterceptor(request) {
  const session = await getSession();
  const accessToken = session?.accessToken?.jwtToken;

  if (accessToken && !request.headers['Authorization']) {
    request.headers['Authorization'] = getAuthorizationHeader(accessToken);
  }

  return request;
}

/**
 * Interceptor to refresh Authorization header.
 *
 * @param {object} error
 *
 * @returns {object}
 */
export async function unauthorizedResponseHandlerInterceptor(error) {
  if (!error.response) {
    return Promise.reject(error);
  }

  const originalRequest = error.config;
  const path = originalRequest.url;

  const isUnAuthorized =
    error.response.status === HttpStatus.UNAUTHORIZED;

  if (isUnAuthorized && path === `/auth/refresh-token`) return redirectToLogin();
  
  if (isUnAuthorized) {
    const session = await getSession();
    const refreshToken = session?.refreshToken?.token;

    if (!refreshToken) {
      // tokenService.clear();

      return redirectToLogin();
    }

    if (isTokenBeingRefreshed) {
      try {
        const newAccessToken = await holdRequest();

        originalRequest.headers['Authorization'] = getAuthorizationHeader(
          newAccessToken
        );

        return api.request(originalRequest);
      } catch (err) {
        return Promise.reject(err);
      }
    }

    isTokenBeingRefreshed = true;

    try {
      await refresh(refreshToken);

      isTokenBeingRefreshed = false;
      const { accessToken } = await getSession();

      originalRequest.headers['Authorization'] = getAuthorizationHeader(
        accessToken?.jwtToken
      );

      releaseHeldRequests(null, accessToken?.jwtToken);

      return api.request(originalRequest);
    } catch (err) {
      releaseHeldRequests(err, null);
      if (err.response && err.response.status === HttpStatus.UNAUTHORIZED) {

        redirectToLogin();
      }
    }
  }

  return Promise.reject(error);
}

/**
 * Release held requests.
 *
 * @param {object} err
 * @param {string} refreshedAccessToken
 */
function releaseHeldRequests(err, refreshedAccessToken = null) {
  heldRequests.forEach((elementPromise) => {
    if (err) {
      elementPromise.reject(err);
    } else {
      elementPromise.resolve(refreshedAccessToken);
    }
  });
  heldRequests = [];
}

/**
 * Hold request in array.
 *
 * @returns {Promise<Array>}
 */
function holdRequest() {
  return new Promise((resolve, reject) => {
    heldRequests.push({ resolve, reject });
  });
}

/**
 * Redirects to the login page.
 */
export function redirectToLogin() {
  logout();
}

function getSession() {
  return new Promise(async (resolve, reject) => {
    const user = await UserPool.getCurrentUser();

    if (!user) {
      return resolve('');
    } else {
      user.getSession((err, session) => {
        if (err) {
          redirectToLogin();
        } else {
          return resolve(session);
        }
      })
    }
  })
}

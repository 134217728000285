import { createAction } from 'redux-actions';

import * as authService from '../../services/auth';

export const LOGIN = 'LOGIN';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_PENDING = 'LOGOUT_PENDING';
export const LOGOUT_REJECTED = 'LOGOUT_REJECTED';
export const LOGOUT_FULFILLED = 'LOGOUT_FULFILLED';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_PENDING = 'REFRESH_TOKEN_PENDING';
export const REFRESH_TOKEN_REJECTED = 'REFRESH_TOKEN_REJECTED';
export const REFRESH_TOKEN_FULFILLED = 'REFRESH_TOKEN_FULFILLED';

export const login = createAction(
  LOGIN,
  authService.login
);

export const logout = createAction(
  LOGOUT,
  authService.logout
)

export const refresh = createAction(
  REFRESH_TOKEN,
  authService.refresh
)

import { createAction } from 'redux-actions';

import * as paymentService from '../../services/payment';

export const GET_EVENT_PAYMENTS = 'GET_EVENT_PAYMENTS';
export const GET_EVENT_PAYMENTS_PENDING = 'GET_EVENT_PAYMENTS_PENDING';
export const GET_EVENT_PAYMENTS_REJECTED = 'GET_EVENT_PAYMENTS_REJECTED';
export const GET_EVENT_PAYMENTS_FULFILLED = 'GET_EVENT_PAYMENTS_FULFILLED';
export const GET_BALANCE = 'GET_BALANCE';
export const GET_BALANCE_PENDING = 'GET_BALANCE_PENDING';
export const GET_BALANCE_REJECTED = 'GET_BALANCE_REJECTED';
export const GET_BALANCE_FULFILLED = 'GET_BALANCE_FULFILLED';
export const GOTO_STRIPE_DASHBOARD = 'GOTO_STRIPE_DASHBOARD';
export const GOTO_STRIPE_DASHBOARD_PENDING = 'GOTO_STRIPE_DASHBOARD_PENDING';
export const GOTO_STRIPE_DASHBOARD_FULFILLED = 'GOTO_STRIPE_DASHBOARD_FULFILLED';
export const GOTO_STRIPE_DASHBOARD_REJECTED = 'GOTO_STRIPE_DASHBOARD_REJECTED';
export const PAYOUT = 'PAYOUT';
export const PAYOUT_PENDING = 'PAYOUT_PENDING';
export const PAYOUT_FULFILLED = 'PAYOUT_FULFILLED';
export const PAYOUT_REJECTED = 'PAYOUT_REJECTED';

export const getEventPayments = createAction(
  GET_EVENT_PAYMENTS,
  paymentService.getEventPayments
)

export const getBalance = createAction(
  GET_BALANCE,
  paymentService.getBalance
)

export const gotoStripeDashboard = createAction(
  GOTO_STRIPE_DASHBOARD,
  paymentService.getStripeDashboardUrl
)

export const payout = createAction(
  PAYOUT,
  paymentService.payout
)

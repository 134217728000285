import React, { Fragment, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { faPlusSquare, faQuestionCircle, faCommentDots } from '@fortawesome/free-solid-svg-icons';

import DashboardHeader from '../../components/dashboard-header';
import MiniCard from '../../components/cards/mini-card';

import CashImg from '../../assets/images/cash.svg';
import NFTImg from '../../assets/images/NFT_Icon.svg';
import GoLive from '../../assets/images/go_live.png';
import AddPost from '../../assets/images/addpost.svg';


import Payments from '../payments';

import {
  getEventPayments,
  getBalance,
  gotoStripeDashboard,
  payout
} from '../../redux/actions/payment';

import './index.scss';

const dashboardMenu = [
  {
    img: CashImg,
    title: 'Payments',
    action: {
      type: 'ROUTE',
      data: {
        type: 'ROUTE',
        url: '/payments'
      }
    }
  },
  {
    img: AddPost,
    title: 'Create Post',
    action: {
      type: 'ROUTE',
      data: {
        type: 'ROUTE',
        url: ''
      }
    }
  },
  {
    img: NFTImg,
    title: 'NFT',
    action: {
      type: 'ROUTE',
      data: {
        type: 'ROUTE',
        url: ''
      }
    }
  },
  {
    img: GoLive,
    title: 'Go Live',
    action: {
      type: 'ROUTE',
      data: {
        type: 'ROUTE',
        url: ''
      }
    }
  },
  {
    icon: faQuestionCircle,
    title: 'Help',
    action: {
      type: 'ROUTE',
      data: {
        type: 'ROUTE',
        url: ''
      }
    }
  },
  {
    icon: faCommentDots,
    title: 'Send Feedback',
    action: {
      type: 'ROUTE',
      data: {
        type: 'ROUTE',
        url: ''
      }
    }
  }
]

const dashboardItemPaths = [
  '/payments'
]

const Dashboard = ({
  payment: {
    events,
    stripeInfo,
    loadingPayout
  },
  getEventPayments,
  getBalance,
  gotoStripeDashboard,
  payout
}) => {
  const location = useLocation();
  const history = useHistory();

  const [activeDashboardItem, setActiveDashboardItem] = useState(null);
  const [hideDashboardMenuInMobile, setHideDashboardMenuInMobile] = useState(true);
  useEffect(() => {
    getStripeBalance();
  }, []);

  useEffect(() => {
    if (dashboardItemPaths.includes(location.pathname)) {
      const slugs = location.pathname.split('/').filter(x => x);
      if(slugs.length > 0) setActiveDashboardItem(slugs[0])
    }
  }, [location])

  useEffect(() => {
    if (activeDashboardItem) {
      setHideDashboardMenuInMobile(true);
    } else {
      setHideDashboardMenuInMobile(false);
    }
  }, [activeDashboardItem])

  const handleAction = (action) => {
    const { type, data } = action;
    switch (type) {
      case 'ROUTE': {
        if(data?.url) history.push(data?.url);
        break;
      }
    }
  }

  const getPaymentDetails = () => {
    getEventPayments();
  }

  const getStripeBalance = () => {
    getBalance();
  }

  const gotoStripe = async (isAccount) => {
    const { url } = await gotoStripeDashboard(isAccount);

    window.location.href = url;
  }

  const handlePayout = async () => {
    await payout();
    getStripeBalance();
  }

  return (
    <Fragment>
      <div className="dashboard-container">
        {!hideDashboardMenuInMobile && <div className="dashboard-header">
          <DashboardHeader
            stripeInfo={stripeInfo}
            gotoStripe={gotoStripe}
            handlePayout={handlePayout}
            loadingPayout={loadingPayout}
          />
        </div>}
        <div className={`dashboard-menu ${hideDashboardMenuInMobile ? 'hide' : ''}`}>
          {dashboardMenu.map((menu, index) => (
            <div className="menu">
              <MiniCard key={`dash-menu-${index}`} item={menu} handleAction={handleAction}></MiniCard>
            </div>
          ))}
        </div>
        {activeDashboardItem ? <div className="dashboard-pages">
          {activeDashboardItem === 'payments' ? <Payments
            events={events}
            stripeInfo={stripeInfo}
            gotoStripe={gotoStripe}
            getPaymentDetails={getPaymentDetails}
            handlePayout={handlePayout}
            loadingPayout={loadingPayout}
          /> : ''}
        </div> : ''}
      </div>
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    payment: state.payment
  }
}

const mapDispatchToProps = {
  getEventPayments,
  getBalance,
  gotoStripeDashboard,
  payout
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

import React, { Fragment } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './index.scss';

const MiniCard = ({ item, handleAction }) => {
  return (
    <Fragment>
      <div className="mini-card-wrapper">
        <Card onClick={()=>handleAction(item?.action)}>
          <Card.Body>
            <div className="icon-wrapper">
              {item?.icon ? <FontAwesomeIcon icon={item?.icon} size="2x"/> : ''}
              {item?.img ? <img src={item?.img}></img> : ''}
            </div>
            <Card.Subtitle>{item?.title}</Card.Subtitle>
          </Card.Body>
        </Card>
      </div>
    </Fragment>
  )
}

export default MiniCard;

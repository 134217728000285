import axios from 'axios';

import config from '../config';

const api = axios.create({
  baseURL: `${config.SERVER_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
});

const authApi = axios.create({
  baseURL: `${config.SERVER_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
});

export { api as default, authApi };

import React, { Fragment } from 'react';
import { Card, Button } from 'react-bootstrap';
import Moment from 'react-moment';

import DefaultUserAvatar from '../../assets/images/default-user.png';

import './index.scss';

const DashboardHeader = ({
  stripeInfo,
  gotoStripe,
  handlePayout,
  loadingPayout
}) => {
  return (
    <Fragment>
      <div className="header-wrapper">
        <Card>
          <Card.Body>
            {stripeInfo?.joinDate ? <Card.Title>Creator since <Moment format="MMMM YYYY">{stripeInfo?.joinedDate}</Moment></Card.Title> : ''}
            <div className="header-info">
              <div className="personal-info">
                <div className="creator-avatar">
                  <img src={stripeInfo?.avatar || DefaultUserAvatar}></img>
                </div>
                <div className="creator-name">
                  <span>{stripeInfo?.name}</span>
                </div>
                <div className="stripe-account" onClick={()=>gotoStripe(true)}>
                  View Stripe account
                </div>
              </div>
              <div className="payment-info">
                <span className="title">Your balance</span>
                <div className="balance">
                  <span>${stripeInfo?.availableBalance}</span>
                </div>
              </div>
              <div className="payout-button">
                <Button
                  variant="success"
                  disabled={!stripeInfo?.availableBalance || loadingPayout}
                  onClick={handlePayout}>
                  {loadingPayout ? 'Processing Payout' : 'Pay out now'}
                </Button>
                <div className="stripe-account" onClick={()=>gotoStripe(false)}>
                  View payouts on Stripe
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </Fragment>
  )
}

export default DashboardHeader;

import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faHome, faCommentDots, faUser} from '@fortawesome/free-solid-svg-icons';

import crBox from '../../assets/images/crbox.svg';

import './index.scss';

const Footer = () => {
  const [isStandAlone, setIsStandAlone] = useState(false);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [isBottomBarHidden, setIsBottomBarHidden] = useState(false);

  useEffect(() => {
    const resizeListener = () => {
      const currentInnerHeight = window.innerHeight;
      if (currentInnerHeight > innerHeight) {
        setIsBottomBarHidden(true);
      } else {
        setIsBottomBarHidden(false);
      }
      
      setInnerHeight(currentInnerHeight);
    }

    if (!isStandAlone) {
      window.addEventListener('resize', resizeListener);
    } else {
      setIsBottomBarHidden(true);
    }

    return () => {
      window.removeEventListener('resize', resizeListener);
    }

  }, [isStandAlone]);

  useEffect(() => {
    setIsStandAlone(navigator.standalone)
  }, []);

  return (
    <Fragment>
      <div className={`footer-wrapper ${isBottomBarHidden && 'bottom-bar-hidden'}`}>
        <nav className="navbar container">
          <ul className="nav">
            <li className="nav-item">
              <Link to='/'>
              <FontAwesomeIcon icon={faHome}/>
              </Link>
            </li>
            <li className="nav-item">
              <Link to='/'>
                <FontAwesomeIcon icon={faBell}/>
              </Link>
            </li>
            <li className="nav-item">
              <Link to='/'>
                <img className="cr-box" src={crBox}></img>
              </Link>
            </li>
            <li className="nav-item">
              <Link to='/'>
                <FontAwesomeIcon icon={faCommentDots}/>
              </Link>
            </li>
            <li className="nav-item">
              <Link to='/'>
                <FontAwesomeIcon icon={faUser}/>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </Fragment>
  )
}

export default Footer;

import React, { Fragment, useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';

import DashboardHeader from '../../components/dashboard-header';
import PayoutDetails from './PayoutDetails';
import './index.scss';

const Payments = ({
  events,
  stripeInfo,
  getPaymentDetails,
  gotoStripe,
  handlePayout,
  loadingPayout
  }) => {
  const [currentEvent, setCurrentEvent] = useState(null);

  useEffect(() => {
    getPaymentDetails();
  }, [])

  const viewDetails = (event) => {
    setCurrentEvent(event);
  }
  return (
    <Fragment>
      {!currentEvent ? <div className="payment-container">
        <DashboardHeader
          stripeInfo={stripeInfo}
          gotoStripe={gotoStripe}
          handlePayout={handlePayout}
          loadingPayout={loadingPayout}
        >
        </DashboardHeader>
        <div className="event-payout-container">
          <h3 className="title">My Events</h3>
          <label className="sub-title">Paid prior to 15 days after event</label>
          <div className="payout-table">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Event Name</th>
                  <th className="hide-on-mobile">Your Ticket Revenue Share</th>
                  <th className="hide-on-mobile">Your Donation Profit Share</th>
                  <th className="hide-on-mobile">Your Payout from Tickets</th>
                  <th className="hide-on-mobile">Your Payout from Donations</th>
                  <th>Your Total Expected Payout</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(events) && events.length > 0 && events.map((event, index) => <tr key={`${event?.name}-index`}>
                  <td>{event?.name}</td>
                  <td className="hide-on-mobile" style={{ 'color': 'red' }}>{event?.revenueShare ? `${event?.revenueShare}%` : '-'}</td>
                  <td className="hide-on-mobile" style={{ 'color': 'red' }}>{event?.donationShare ? `${event?.donationShare}%` : '-'}</td>
                  <td className="hide-on-mobile">{event?.revenueDetail?.expectedpayout ? `$${event?.revenueDetail?.expectedpayout}` : '-'}</td>
                  <td className="hide-on-mobile">{event?.donationDetail?.expectedpayout ? `$${event?.donationDetail?.expectedpayout}` : '-'}</td>
                  <td>{event?.expectedAmount ? `$${event?.expectedAmount}` : '-'}</td>
                  <td><FontAwesomeIcon icon={faFileAlt} onClick={() => viewDetails(event)} /></td>
                </tr>)}
              </tbody>
            </Table>
          </div>
        </div>
      </div> : <div className="payment-container">
          <Button
            variant="outline-secondary"
            onClick={() => setCurrentEvent(null)}>
            Back
          </Button>
          <PayoutDetails payoutDetail={currentEvent}/>
      </div>}
    </Fragment>
  )
}

export default Payments;

import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faHome, faCommentDots, faUser} from '@fortawesome/free-solid-svg-icons';

import studioImg from '../../assets/images/Studio.png';
import crBox from '../../assets/images/crbox.svg';

import config from '../../config';

import './index.scss';

const Navbar = () => {
  return (
    <div className='nav-wrapper fixed-top'>
      <nav className="navbar container">
        <Link to="/">
          <img className='logo' src={studioImg} alt="CR" draggable={false} />
        </Link>
        <Fragment>
          <ul className="nav">
            <li className="nav-item">
            <Link to="" onClick={()=>window.location.href=`${config.MAIN_DOMAIN}/`}>
                <FontAwesomeIcon icon={faHome}/>
              </Link>
            </li>
            <li className="nav-item">
              <Link to='/'>
                <FontAwesomeIcon icon={faBell}/>
              </Link>
            </li>
            <li className="nav-item">
              <Link to='/'>
                <img className="cr-box" src={crBox}></img>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="" onClick={()=>window.location.href=`${config.MAIN_DOMAIN}/messaging`}>
                <FontAwesomeIcon icon={faCommentDots}/>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="" onClick={()=>window.location.href=`${config.MAIN_DOMAIN}/dashboard`}>
                <FontAwesomeIcon icon={faUser}/>
              </Link>
            </li>
          </ul>
        </Fragment>
      </nav>
    </div>
  );
};

export default Navbar;

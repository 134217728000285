import React, { Fragment } from 'react';
import { Table } from 'react-bootstrap';

const PayoutDetails = ({
  payoutDetail
}) => {
  return <Fragment>
    {payoutDetail ? <div className="payout-detail-container">
      <div className="event-payout-container">
        <h3 className="d-inline">{payoutDetail?.name}</h3>
        <div className="payout-table full-info">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="hide-on-mobile">Event Name</th>
                <th>Your Ticket Revenue Share</th>
                <th>Your Payout from Tickets</th>
                <th>Your Donation Profit Share</th>
                <th>Your Payout from Donations</th>
                <th className="hide-on-mobile">Your Total Expected Payout</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="hide-on-mobile">{payoutDetail?.name}</td>
                <td style={{ 'color': 'red' }}>{payoutDetail?.revenueShare ? `${payoutDetail?.revenueShare}%` : '-'}</td>
                <td>{payoutDetail?.revenueDetail?.expectedpayout ? `$${payoutDetail?.revenueDetail?.expectedpayout}` : '-'}</td>
                <td style={{ 'color': 'red' }}>{payoutDetail?.donationShare ? `${payoutDetail?.donationShare}%` : '-'}</td>
                <td>{payoutDetail?.donationDetail?.expectedpayout ? `$${payoutDetail?.donationDetail?.expectedpayout}` : '-'}</td>
                <td className="hide-on-mobile">{payoutDetail?.expectedAmount ? `$${payoutDetail?.expectedAmount}` : '-'}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="">
          <div className="payout-table limited-info">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Your Ticket Revenue Share</th>
                  <th>Your Payout from Tickets</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ 'color': 'red' }}>{payoutDetail?.revenueShare ? `${payoutDetail?.revenueShare}%` : '-'}</td>
                  <td>{payoutDetail?.revenueDetail?.expectedpayout ? `$${payoutDetail?.revenueDetail?.expectedpayout}` : '-'}</td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="payout-table limited-info">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Your Donation Profit Share</th>
                  <th>Your Payout from Donations</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ 'color': 'red' }}>{payoutDetail?.donationShare ? `${payoutDetail?.donationShare}%` : '-'}</td>
                  <td>{payoutDetail?.donationDetail?.expectedpayout ? `$${payoutDetail?.donationDetail?.expectedpayout}` : '-'}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-sm-5 tickets-wrapper">
          <h4>Tickets</h4>
          <div className="tickets-container">
            <div className="item">
              <div>{payoutDetail?.revenueDetail?.totalTicketSold || '-'}</div>
              <div>Tickets Sold</div>
            </div>
            <div className="item">
              <span className="prepend">X</span>
              <div>{payoutDetail?.revenueDetail?.ticketPrice ? `$${payoutDetail?.revenueDetail?.ticketPrice}` : '-'}</div>
              <div>Ticket Price</div>
            </div>
          </div>
          <div className="revenue-container">
            <div className="item">
              <div>{payoutDetail?.revenueDetail?.totalRevenue ? `$${payoutDetail?.revenueDetail?.totalRevenue}` : '-'}</div>
              <div>Total Revenue</div>
            </div>
            <div className="item">
              <span className="prepend">X</span>
              <div className="share-percent">{payoutDetail?.revenueShare ? `${payoutDetail?.revenueShare}%` : '-'}</div>
              <div>Your Revenue Share</div>
            </div>
          </div>
          <div className="total-payout">
            <div className="item">
              <div>{payoutDetail?.revenueDetail?.expectedpayout ? `$${payoutDetail?.revenueDetail?.expectedpayout}` : '-'}</div>
              <div>Expected Payout</div>
            </div>
          </div>
        </div>
        <div className="col-sm-5 donations-wrapper">
          <h4>Donations</h4>
          <div className="tickets-container">
            <div className="item">
              <div>{payoutDetail?.donationDetail?.totalDonation ? `$${payoutDetail?.donationDetail?.totalDonation}` : '-'}</div>
              <div>Total Donation Revenue</div>
            </div>
            <div className="item">
              <span className="prepend">-</span>
              <div>{payoutDetail?.donationDetail?.stripeFees ? `$${payoutDetail?.donationDetail?.stripeFees}` : '-'}</div>
              <div>Total Stripe Fees</div>
            </div>
          </div>
          <div className="revenue-container">
            <div className="item">
              <div>{payoutDetail?.donationDetail?.totalProfit ? `$${payoutDetail?.donationDetail?.totalProfit}` : '-'}</div>
              <div>Total Profit</div>
            </div>
            <div className="item">
              <span className="prepend">X</span>
              <div className="share-percent">{payoutDetail?.donationShare ? `${payoutDetail?.donationShare}%` : '-'}</div>
              <div>Your Revenue Share</div>
            </div>
          </div>
          <div className="total-payout">
            <div className="item">
              <div>{payoutDetail?.donationDetail?.expectedpayout ? `$${payoutDetail?.donationDetail?.expectedpayout}` : '-'}</div>
              <div>Expected Payout</div>
            </div>
          </div>
        </div>
      </div>
    </div> : ''}
  </Fragment>
}

export default PayoutDetails;

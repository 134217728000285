import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashboard from '../pages/dashboard';

const Routes = () => {
  return (
    <Fragment>
      <Switch>
        <Route exact path='/' component={Dashboard}></Route>
        <Route exact path='/payments' component = {Dashboard}></Route>
      </Switch>
    </Fragment>
  )
}

export default Routes;

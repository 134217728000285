import {
  LOGIN_PENDING,
  LOGIN_FULFILLED,
  LOGIN_REJECTED
} from '../actions/auth';

const initialState = {};

export default function (state = initialState, action) {
  // TODO: Update redux state based on the action type
  switch (action.type) {
    case LOGIN_FULFILLED:
      return state;
    case LOGIN_PENDING:
      return state;
    case LOGIN_REJECTED:
      return state;
    default:
      return state;
  }
}
